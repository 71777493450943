<template>
  <div style="background: #F6F6F6;">
    <!-- 导航栏 -->
    <div class="header">
      <img class="img-a" src="@/assets/GeImg/houtui.png" alt="" @click="$router.go(-1)">
      <span class="span-a">外卖</span>
      <img class="img-b" src="@/assets/GeImg/weizhi.png" alt="" @click="$router.go(0)">
      <span>尚泽大都会-E座</span>
      <img src="@/assets/GeImg/qianjin.png" alt="" @click="$router.go(1)">
    </div>
    <!-- /导航栏 -->
    <!-- 搜索栏 -->
    <van-search shape="round" v-model="value" placeholder="输入商家姓名" />
    <!-- /搜索栏 -->
    <!-- 图片展示 -->
    <div class="img-box" style="margin: 10px 0 20px;">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8d70b1812a2d69a62637b62f46e4978aea2cd9f92e556d8e05b04af4cfac2b70" alt="">
        </van-swipe-item>
        <van-swipe-item>
          <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8d70b1812a2d69a62637b62f46e4978aea2cd9f92e556d8e05b04af4cfac2b70" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- /图片展示 -->
    <!-- 分类 -->
    <div class="grid-box">
      <van-grid :column-num="5" center clickable icon-size="40">
        <van-grid-item to='/outsell/catedetail' v-for="(item, index) in cate" :key="index" :icon="item.img" :text="item.text" />
      </van-grid>
    </div>
    <!-- /分类 -->
   <!-- 推荐外卖 -->
    <div class="list-two-box">
    <div class="list-two">
      <div class="list-two-item">
        <img class="list-two-item-img" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbc32dba9a95f41afd94bd59bc8f466d91fc0c354bcad529450bac89091061731" alt="">
        <div class="list-two-right">
          <div class="title">
            <span class="title-span-left">烤鱼先生·香辣烤...</span>
            <span class="title-span-right">0.5km</span>
          </div>
          <div class="discount">
            <div>
              <span>20减20</span>
            </div>
            <div>
              <span>20减20</span>
            </div>
            <div class="span-right">
              <span>20减20</span>
            </div>
          </div>
          <div class="banner">
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
            <div class="banner-item">
              <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga526d643b73b38df0d58d50f4aa317b8599f05c88259e6ed890c1dcaa418ef47" alt="">
              <p>糖醋里脊...</p>
              <div class="banner-bottom">
                <span class="banner-a">￥10.9</span>
                <span class="banner-b">16.9</span>
              </div>
            </div>
          </div>
         </div>
       </div>
    </div>
    </div>
   <!-- /推荐外卖 -->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        value: '', // 搜索栏
        cate: [
          {
            img: require('@/assets/GeImg/danche.png'),
            text: '甜点饮品'
          },
          {
            img: require('@/assets/GeImg/chaoshi.png'),
            text: '超市便利'
          },
          {
            img: require('@/assets/GeImg/zhuanpan.png'),
            text: '蔬菜水果'
          },
          {
            img: require('@/assets/GeImg/feiji.png'),
            text: '买药'
          },
          {
            img: require('@/assets/GeImg/lanzi.png'),
            text: '浪漫烟花'
          },
          {
            img: require('@/assets/GeImg/lanzi.png'),
            text: '早餐'
          },
          {
            img: require('@/assets/GeImg/saoba.png'),
            text: '正品优选'
          },
          {
            img: require('@/assets/GeImg/qiche.png'),
            text: '夜宵'
          },
          {
            img: require('@/assets/GeImg/fangzi.png'),
            text: '文本内容'
          },
          {
            img: require('@/assets/GeImg/fenlei.png'),
            text: '文本内容'
          }
        ]
      }
    },
    created () {
      this.setRem()
        const width = document.documentElement.getBoundingClientRect().width
        document.documentElement.style.fontSize = width/10 + 'px'
    },
    methods: {
    },
   
  }
</script>

<style scoped>
img {
  vertical-align: middle;
}
.header {
  height: 1.4595rem;
  display: flex;
  align-items: center;
  background: white;
}
.img-a {
  margin: 0 0.2703rem 0 0.2703rem;
}
.span-a {
  font-weight: bold;
  margin-right: 3.7838rem;
  color: #333;
  font-size: 0.4865rem;
}
.img-b {
  margin: 0 0.0811rem 0 0.0811rem;
}
.bigImg {
  margin-top: 0.2703rem;
  width: 9.4324rem;
  display: block;
}
.list-two-box {
  display: flex;
  justify-content: center;
}
.list-two {
  width: 9.5946rem;
  margin-top: 0.2703rem;
  display: flex;
  justify-content: center;
  background: white;
}
.list-two-item {
  margin-top: 0.2703rem;
  /* width: 9.5946rem; */
  display: flex;
  padding: 0 0.2703rem 0 0.2703rem;
}
.list-two-item-img {
  height: 3.8919rem;
}
.list-two-right {
  flex: 1;
  padding: 0 0 0 0.2432rem;
  margin-bottom: 0.2703rem;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.0811rem;
}
.title-span-left {
  font-weight: bold;
  font-size: 0.4324rem;
}
.title-span-right {
  font-size: 0.3784rem;
  color: #999;
}
.discount {
  width: 3.7838rem;
  height: 0.4054rem;
  border: 0.027rem solid #FF5200;
  display: flex;
  font-size: 0.3243rem;
  display: flex;
  align-items: center;
  color: #FF5200;
}
.discount div {
  height: 0.3243rem;
  border-right: 1px solid #FF5200;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.0811rem;
  padding-right: 0.1081rem;
}
.discount .span-right {
  border: 0;
}
.banner {
  width: 5.8649rem;
  margin-top: 0.0811rem;
  display: flex;
  overflow: auto;
  /* border: 1px solid green; */
}
.banner::-webkit-scrollbar { display: none; }
.banner p {
  margin-top: 0.0811rem;
}
.banner-item {
  width: 2.0541rem;
  height: 2.6486rem;
  font-size: 0.3243rem;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.2432rem;
  /* background: red; */
}
.banner-bottom {
  display: flex;
  margin-top: 0.0811rem;
}
.banner-bottom .banner-a {
  font-weight: bold;
  color: red;
}
.banner-bottom .banner-b {
  margin-left: 0.0811rem;
  text-decoration: line-through;
}
.van-grid-item__text span {
  font-size: 0.3243rem;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 2.5405rem;
  text-align: center;
  background-color: #fff;
}
</style>